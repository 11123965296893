import { createContext, useContext, useState } from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";

const dark = {
  palette: {
    mode: "dark",
  },

};
const light = {
  palette: {
    mode: "light",
  },
};
const ThemeContext = createContext();

export const ThemeHoc = ({ children }) => {
  const [isDark, setIsDark] = useState(false);
  const changeTheme = () => {
    setIsDark(!isDark);
  };

  return (
    <ThemeContext.Provider value={{ isDark, changeTheme }}>
      <ThemeProvider theme={isDark ? createTheme(dark) : createTheme(light)}>
        {children}
      </ThemeProvider>
    </ThemeContext.Provider>
  );
};

export const useThemeHoc = () => useContext(ThemeContext);
