import React from "react";
import { ReactComponent as Raizel } from "../assets/raizel.svg";
import { ReactComponent as Right } from "../assets/right.svg";
import { ReactComponent as Left } from "../assets/left.svg";
import { ReactComponent as Center } from "../assets/center.svg";
import "./Loading.css";
function Loading() {
  return (
    <div className="commingSoon">
      <div className="flowerSvg">
        <Left /><Center /><Right />
      </div>
      <div className="logoSvg">
        <Raizel style={{ maxWidth: "300px", width: "100%", height: "100px"}} />
      </div>
    </div>
  );
}

export default Loading;
