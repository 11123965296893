import React, { useState } from "react";
import {
  Card,
  CardHeader,
  Avatar,
  CardContent,
  Typography,
  CardActions,
  IconButton,
  Tooltip
} from "@mui/material";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { motion } from "framer-motion";
import { ContentPaste } from "@mui/icons-material";

function UserTemplate({ elem, mode }) {
  const [openTooltip, setOpenTooltip] = useState(false);

  const handleClick = (link) => {
    navigator.clipboard.writeText(link);
    setOpenTooltip(true);
    setTimeout(() => {
      setOpenTooltip(false);
    }, 1000);
  };

  return (
    <motion.div
      style={{ display: "flex", justifyContent: "center", width: "100%" }}
      transition={{ duration: 0.6 }}
      animate={{ scale: 1, opacity: 1 }}
      initial={{ scale: 0, opacity: 0 }}
      exit={{ scale: 0, opacity: 0 }}
      layout
    >
      <Card
        variant="outlined"
        sx={{
          display: "flex",
          flexDirection: mode === "comp" ? "column" : "row",
          justifyContent: "space-between",
          maxWidth: "1200px",
          width: "100%",
          height: "100%",
        }}
      >
        <CardHeader
          sx={{ display: "flex", flexDirection: mode === "comp" ? "row" : { xs: "column", md: "row" }, width: mode === "comp" ? "100%" : { xs: "20%", md: "50%" } }}
          avatar={
            <Avatar
              src={elem.pagemap.cse_image[0].src}
              sx={{ bgcolor: "red", width: 56, height: 56 }}
              aria-label="recipe"
            />
          }
          title={
            elem.pagemap.metatags[0]["profile:first_name"] +
            " " +
            elem.pagemap.metatags[0]["profile:last_name"]
          }
          subheader={elem.title.split("-")[1]}
        />
        <CardContent>
          {<Typography
            dangerouslySetInnerHTML={{
              __html: mode === "comp" ? elem.htmlSnippet.slice(0, 80) + "..." : elem.htmlSnippet,
            }}
            variant="body2"
            color="text.secondary"
          ></Typography>}
        </CardContent>
        <CardActions sx={{ display: "flex", justifyContent: mode === "comp" ? "flex-start" : "center", flexDirection: mode === "comp" ? { xs: "row" } : { xs: "column", md: "row" } }} >
          <a rel="noreferrer" href={elem.formattedUrl} target="_blank">
            <IconButton sx={{ color: "#1976d2" }}>
              <LinkedInIcon />
            </IconButton>
          </a>
          <IconButton onClick={() => handleClick(elem.formattedUrl)}>
            <Tooltip title="copied!" arrow open={openTooltip} placement="right">
              <ContentPaste />
            </Tooltip>
          </IconButton>
        </CardActions>
      </Card>
    </motion.div >
  );
}

export default UserTemplate;
