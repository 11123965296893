import { updateProfile } from "firebase/auth";
import { auth, storage } from "../firebase/firebase";
import { useUserContxt } from "../context/authenticationContext";
import { useState } from "react";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";

export const useUpdate = () => {
  const { dispatch } = useUserContxt();
  const [error, setError] = useState(null);
  const [isPendingImage, setIsPendingImage] = useState(null);
  const [isPendingName, setIsPendingName] = useState(null);
  const [what, setWhat] = useState(null);
  const updateImage = (img) => {
    setWhat(null);
    setError(null);
    setIsPendingImage(true);

    const extention = img.type.split("/")[1];
    const fileRef = ref(storage, auth.currentUser.uid + "." + extention);
    uploadBytes(fileRef, img)
      .then((res) => {
        getDownloadURL(fileRef)
          .then((url) => {
            updateProfile(auth.currentUser, { photoURL: url }).then((r) => {
              dispatch({
                type: "AUTH_IS_READY",
                payload: auth.currentUser,
              });
              setIsPendingImage(false);
              setWhat("image");
            });
          })
          .catch((err) => {
            setError(err.message);
            setIsPendingImage(false);
          });
      })
      .catch((err) => {
        setError(err.message);
        setIsPendingImage(false);
      });
  };
  const updateName = (name) => {
    setWhat(null);
    setError(null);
    setIsPendingName(true);
    updateProfile(auth.currentUser, { displayName: name })
      .then((resp) => {
        dispatch({ type: "AUTH_IS_READY", payload: auth.currentUser });
        setIsPendingName(false);
        setWhat("name");
      })
      .catch((err) => {
        setError(err.message);
        setIsPendingName(false);
      });
  };

  return {
    updateImage,
    what,
    updateName,
    isPendingImage,
    isPendingName,
    error,
  };
};
