export const getData = async (elem,max) => {
  const results = [];
  let start = 1;
  while (results.length < max && start <= 91) {
    const resp = await fetch(`https://www.googleapis.com/customsearch/v1?key=${process.env.REACT_APP_API_KEY}&q=site:linkedin.com/in intitle:${elem}&cx=${process.env.REACT_APP_API_ENGINE_ID}&start=${start}`);
    if (!resp.ok) {
      throw new Error(`there is a problem with : ${elem}`);
    }
    const data = await resp.json();
    const items = data.items;
    console.log(items);
    if(items){
      results.push(...items);
      start += 10;
    }else{
      break;
    }
  }
  return results.slice(0, max);
};