import React from "react";
import { ReactComponent as Svg } from "../assets/notfound.svg";
import "./notFound.css";
import { Link } from "react-router-dom";
import { Button } from "@mui/material";
function NotFound() {
  return (
    <div
      className="notfound"
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div className="group">
        <Link to="/" style={{ textDecoration: "none", margin: "20px" }}>
          <Button
            variant="contained"
            sx={{ bgcolor: "#FF6584", "&:hover": { bgcolor: "#ff2652" } }}
          >
            BACK
          </Button>
        </Link>
        <Svg />
      </div>
    </div>
  );
}

export default NotFound;
