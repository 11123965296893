import React from 'react'
import { Box, TextField, Toolbar, InputAdornment } from '@mui/material'
import SearchIcon from "@mui/icons-material/Search";

function InnerSearch({ filter, setFilter }) {
    return (
        <Box
            sx={{
                display: "flex",
                mb: 1,
                alignItems: "center",
                justifyContent: "flex-start",
            }}
            >
            <Toolbar
                variant="dense"
                sx={{ display: "flex", justifyContent: "center" }}
            >
                <TextField
                id="input-with-icon-textfield"
                label="Filter Users"
                onChange={(e) => setFilter(e.target.value)}
                defaultValue={filter}
                sx={{ minWidth: { md: "300px", lg: "400px" }, mt: 1 }}
                InputProps={{
                    startAdornment: (
                    <InputAdornment position="start">
                        <SearchIcon />
                    </InputAdornment>
                    ),
                }}
                variant="standard"
                />
            </Toolbar>
        </Box>
    )
}

export default InnerSearch