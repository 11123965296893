import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./index.css";
import { Dashboard, Login } from "./pages";
import { ThemeHoc } from "./context/themeContext";
import NotFound from "./NotFoundPage/NotFound";
import { UserProvider } from "./context/authenticationContext";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Dashboard />,
    errorElement: <NotFound />,
  },
  {
    path: "/login",
    element: <Login />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <UserProvider>
    <ThemeHoc>
      <RouterProvider router={router} />
    </ThemeHoc>
  </UserProvider>
);
