import React from "react";
import { UserTemplate } from "../components";
import { motion, AnimatePresence } from "framer-motion";
import { Divider } from "@mui/material";

function Container({ data, mode, filter }) {
  return (
    data && (
      <motion.div
        layout
        style={{
          width: "100%",
          display: "grid",
          columnGap: "10px",
          rowGap: "20px",
          gridTemplateColumns: mode === "comp" ? "repeat(auto-fit, minmax(250px,6fr))" : "1fr"
        }}
      >
        <AnimatePresence>
          {
            data.map((table, i) => {
              return <React.Fragment key={i}>
                {
                  table?.filter(elem => {
                    if (filter === "") {
                      return elem;
                    }
                    return elem.pagemap.metatags[0]["profile:first_name"].toLowerCase().includes(filter.toLowerCase()) || elem.pagemap.metatags[0]["profile:last_name"].toLowerCase().includes(filter.toLowerCase()) || elem.htmlSnippet.toLowerCase().includes(filter.toLowerCase())
                  }).map((elem, i) => {
                    return <UserTemplate mode={mode} key={elem.title + i} elem={elem} />;
                  })
                }
                <Divider style={{ gridColumn: "1 / -1", width: "100%", height: "12fr" }} />
              </React.Fragment>
            })

          }
        </AnimatePresence>
      </motion.div >
    )
  );
}

export default Container;
