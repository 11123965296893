import { createContext, useContext, useEffect, useReducer } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../firebase/firebase";

const UserContext = createContext();

const initialState = { user: null, authIsReady: false, isPnding: true };

export const reducer = (state, action) => {
  switch (action.type) {
    case "LOGIN":
      return { user: action.payload, authIsReady: true, isPending: false };
    case "LOGOUT":
      return { user: null, authIsReady: false, isPending: false };
    case "AUTH_IS_READY":
      return { user: action.payload, authIsReady: true, isPending: false };
    case "NOT_PENDING":
      return { ...state, isPending: false };
    case "PENDING":
      return { ...state, isPending: true };
    default:
      throw new Error();
  }
};

export const UserProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    dispatch({ type: "PENDING" });
    const unsub = onAuthStateChanged(auth, (user) => {
      dispatch({ type: "AUTH_IS_READY", payload: user });
      unsub();
    });
  }, []);

  return (
    <UserContext.Provider value={{ ...state, dispatch }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUserContxt = () => useContext(UserContext);
