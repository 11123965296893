import { useContext, useEffect } from "react";
import { createContext, useState } from "react";
import { useCollection } from "../hooks/useCollection";
import { useUserContxt } from "./authenticationContext";

const HistoryData = createContext(null);

export const HistoryContext = ({ children }) => {
    const { user } = useUserContxt();
    const [history, setHistory] = useState(null)
    const { documents } = useCollection("histories", user ? ["uid", "==", user.uid] : null)

    useEffect(() => {
        if (documents.length >= 1) {
            setHistory(documents[0])
        }
    }, [documents])

    return (
        <HistoryData.Provider value={{ ...history }} >
            {children}
        </HistoryData.Provider>
    )
}

export const useHistoryContext = () => useContext(HistoryData)