import { Box, Typography, Drawer, List, ListItem, ListItemButton, Chip, Toolbar } from '@mui/material'
import React, { useId } from 'react'
import { useHistoryContext } from '../context/historyContext';
import { useTabsContext } from '../context/tabsContext';

const compare = (arr1, arr2) => {
    if (arr1.length !== arr2.length) {
        return false;
    }
    for (let i = 0; i < arr1.length; i++) {
        if (arr1[i] !== arr2[i]) {
            return false;
        }
    }
    return true;
}

function HistoryDiv() {
    const ids = useId();
    const history = useHistoryContext();
    const { tabs, setTabs, setActive } = useTabsContext();
    return (
            <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                <Drawer variant="permanent"
                    sx={{
                        '@media (min-width: 2029px)': {
                            position: 'fixed',
                        },
                        display: { xs: "none", md: "block" },
                        width: 240,
                        flexShrink: 0,
                        [`& .MuiDrawer-paper`]: { width: 240, boxSizing: 'border-box' },
                    }}>
                    <Toolbar />
                    <Typography variant="h6" sx={{ p: 2, textAlign: "center" }}>History</Typography>
                    <Box sx={{ overflow: 'auto' }}>
                        <List>
                            {history.history &&
                                history.history.map((elem) => {
                                    const show = [...JSON.parse(elem)];

                                    return (
                                        <ListItem key={elem} disablePadding sx={{overflowX: "hidden"}}>
                                            <ListItemButton onClick={() => {
                                                let data = [...tabs];
                                                let found = data.find(t => t.params.length === 0)
                                                if (found) {
                                                    let index = data.indexOf(found);
                                                    data.splice(index, 1, { ...found, params: [...show] })
                                                    setActive(found.id)
                                                } else if (data.find(el => compare(el.params, show))) {
                                                    setActive(data.find(el => compare(el.params, show)).id)
                                                } else {
                                                    data.push({ id: ids + Date.now().toString(), name: `new ${Number(tabs[tabs.length - 1].name.split(" ")[1]) + 1} Tab`, params: [...show] })
                                                    setActive(data[data.length - 1].id)
                                                }
                                                setTabs([...data])

                                            }} >
                                                <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                                                    {show.map((chip) => {
                                                        return <Chip label={chip} key={chip} sx={{cursor: "pointer"}} />;
                                                    })}
                                                </Box>
                                            </ListItemButton>
                                        </ListItem>
                                    )
                                })
                            }

                        </List>
                    </Box>

                </Drawer>
            </Box>
    )
}

export default HistoryDiv