import { useContext } from "react";
import { useState, createContext, useId } from "react";
const TabsContext = createContext();

export const TabsProvider = ({ children }) => {
    let id = useId();
    const [tabs, setTabs] = useState([{ id: id, name: "NEW 0 TAB", params: [] }])
    const [active, setActive] = useState();
    return (
        <TabsContext.Provider value={{ tabs: [...tabs], setTabs: (el) => setTabs(el), active, setActive: (e) => setActive(e) }}>
            {children}
        </TabsContext.Provider>
    )
}

export const useTabsContext = () => useContext(TabsContext)