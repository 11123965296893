import React, { useEffect, useState } from "react";
import {
  Box,
  Chip,
  Autocomplete,
  TextField,
  IconButton,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import { useThemeHoc } from "../context/themeContext"
import SearchIcon from '@mui/icons-material/Search';
import { getData } from "../fetches/fetchApi"
import { useTheme } from '@mui/material/styles';
import { useUserContxt } from "../context/authenticationContext";
import { useUpdateDoc } from "../hooks/useUpdateDoc"
import { useHistoryContext } from "../context/historyContext"
import { useTabsContext } from "../context/tabsContext";


function SearchBar({ setSearch, tab, leng }) {
  const { user } = useUserContxt()
  const theme = useTheme()
  const { isDark } = useThemeHoc()
  const [tags, setTags] = useState([]);
  const [chip, setChip] = useState([]);
  const { updateDocByID, createOne } = useUpdateDoc();
  const historyObj = useHistoryContext();
  const { tabs, setTabs } = useTabsContext();
  const [max, setMax] = useState(10)

  const handleClick = () => {
    const searchData = chip.map(elem => ({
      queryKey: ["search", elem, max],
      queryFn: () => getData(elem, max)
    }))

    if (!historyObj?.id && user.uid && chip.length >= 1) {
      const myData = JSON.stringify(chip)
      createOne(user.uid, myData)
    } else if (historyObj?.id && chip.length >= 1) {
      const myData = JSON.stringify(chip)
      let myArray = [...historyObj.history]
      if (myArray.includes(myData)) {
        myArray = myArray.filter(elem => elem !== myData);
      }
      myArray.unshift(myData);
      updateDocByID(historyObj.id, "history", myArray)
    }
    const myTab = { ...tab, params: [...chip] }
    const index = tabs.indexOf(tab);
    let data = [...tabs];

    data.splice(index, 1, myTab);
    setTabs([...data])
    setSearch([...searchData])


  }

  useEffect(() => {
    setChip([...tab.params])
  }, [tab])


  return (
    <Box sx={{ width: "auto", display: "flex", alignItems: "center", flexDirection: { xs: "column", md: "row" } }} >
      <Box sx={{
        width: { xs: "100%", md: "auto" }, height: "fit-content", boxShadow: 5, display: "flex", background: isDark ? theme.palette.action.selected : "white", borderRadius: 10
      }} >

        <Autocomplete
          disablePortal
          sx={{
            width: { xs: "100%", md: "auto" },
            minWidth: { md: "300px", lg: "500px" },
            mr: 1,
          }}
          value={[...chip]}
          style={{ border: "none", outline: "none" }}
          multiple
          id="tags-outlined"
          options={[]}
          defaultValue={[]}
          onChange={(event, value) => {
            setChip([...value])
          }}
          freeSolo
          renderTags={(value, getTagProps) =>
            value.map((option, index) => {
              return (
                <Chip
                  variant="outlined"
                  label={option}
                  {...getTagProps({ index })}
                />
              );
            })
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label="Queries"
              type="search"
              placeholder="add a company name..."
              value={tags}
              sx={{
                "& fieldset": { border: 'none' },
              }}
              onKeyDown={(e) => {
                setTags([...tags, e.target.value]);
              }}
            />
          )}
        />
        <IconButton onClick={handleClick} >
          <SearchIcon />
        </IconButton>
      </Box>
      <FormControl sx={{ m: 1, minWidth: 120 }}>
        <Select
          value={max}
          onChange={(e) => setMax(e.target.value)}
          displayEmpty
          inputProps={{ 'aria-label': 'Number of results' }}
        >
          <MenuItem value={10}>10</MenuItem>
          <MenuItem value={20}>20</MenuItem>
          <MenuItem value={30}>30</MenuItem>
          <MenuItem value={40}>40</MenuItem>
          <MenuItem value={50}>50</MenuItem>
          <MenuItem value={60}>60</MenuItem>
          <MenuItem value={70}>70</MenuItem>
          <MenuItem value={80}>80</MenuItem>
          <MenuItem value={90}>90</MenuItem>
          <MenuItem value={100}>100</MenuItem>

        </Select>

      </FormControl>
    </Box>
  );
}

export default SearchBar;
