import React, { useEffect, useState } from "react";
import {
  LinearProgress,
  TextField,
  FormControl,
  Input,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  DialogTitle,
  AppBar,
  Box,
  Avatar,
  Container,
  Toolbar,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import MoreIcon from "@mui/icons-material/MoreVert";
import useLogout from "../hooks/useLogout";
import { useUserContxt } from "../context/authenticationContext";
import { useNavigate } from "react-router-dom";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import LightModeIcon from "@mui/icons-material/LightMode";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";

import { useUpdate } from "../hooks/useUpdate";
import { useThemeHoc } from "../context/themeContext";

import lightRaizel from "../assets/lightRaizel.png";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

function Header() {
  const { isDark, changeTheme } = useThemeHoc();
  const [url, setUrl] = useState(null);
  const [name, setName] = useState("");
  const [photo, setPhoto] = useState("");

  const handleFileChoosed = (e) => {
    if (e.target.files[0]) {
      setUrl(URL.createObjectURL(e.target.files[0]));
      setPhoto(e.target.files[0]);
    }
  };

  const { user, isPending } = useUserContxt();
  const { logout, error } = useLogout();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const { updateImage, updateName, isPendingImage, isPeningName } = useUpdate();

  const submitHandle = (e) => {
    e.preventDefault();
    handleMenuClose();

    if (user.displayName !== name) {
      updateName(name);
    }
    if (photo) {
      updateImage(photo);
    }
  };

  const handleClickOpenDialog = () => {
    setOpen(true);
  };
  const handleCloseDialog = () => {
    setOpen(false);
  };

  const handleLogout = () => {
    logout();
  };
  useEffect(() => {
    if (error) {
    }
  }, [error]);

  useEffect(() => {
    if (user) {
      if (user.displayName) {
        setName(user.displayName);
      } else {
        setName("");
      }
    }
  }, [user]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      sx={{ mt: "45px" }}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleClickOpenDialog}>SETTINGS</MenuItem>
      <MenuItem onClick={handleLogout}>LOGOUT</MenuItem>
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem onClick={handleProfileMenuOpen} sx={{ fontWeight: 700 }}>
        <IconButton
          size="medium"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <Avatar
            sx={{ bgcolor: "#f07b2d" }}
            alt="Remy Sharp"
            src={user.photoURL ? user.photoURL : ""}
          >
            {user.displayName ? user.displayName[0] : "Username"[0]}
          </Avatar>
        </IconButton>
        <p>{user.displayName ? user.displayName : "Username"}</p>
      </MenuItem>
    </Menu>
  );

  return (
    user &&
    !isPending && (
      <AppBar
        position="fixed"
        sx={{ fontWeight: 700, zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <img
              src={lightRaizel}
              width={70}
              height={40}
              style={{ cursor: "pointer" }}
              alt="Raizel."
              onClick={() => navigate("/")}
            />

            <Box sx={{ flexGrow: 1 }} />
            <IconButton color="inherit" onClick={() => changeTheme()}>
              {isDark ? <LightModeIcon /> : <DarkModeIcon />}
            </IconButton>
            <Box sx={{ display: { xs: "none", md: "block" } }}>
              <Button
                size="large"
                edge="end"
                aria-label="account of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={handleProfileMenuOpen}
                color="inherit"
                sx={{ fontWeight: 500 }}
              >
                {user.displayName ? user.displayName : "Username"}
                <Avatar
                  sx={{ ml: 1, bgcolor: "#f07b2d" }}
                  alt="Remy Sharp"
                  src={user.photoURL ? user.photoURL : ""}
                >
                  {"Username"[0]}
                </Avatar>
              </Button>
            </Box>

            <Box sx={{ display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                aria-label="show more"
                aria-controls={mobileMenuId}
                aria-haspopup="true"
                onClick={handleMobileMenuOpen}
                color="inherit"
              >
                <MoreIcon />
              </IconButton>
            </Box>
          </Toolbar>
          {renderMobileMenu}
          {renderMenu}
        </Container>
        <BootstrapDialog
          onClose={handleCloseDialog}
          aria-labelledby="customized-dialog-title"
          maxWidth="md"
          open={open}
        >
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={handleCloseDialog}
          >
            {user.displayName ? user.displayName : "Username"}
          </BootstrapDialogTitle>
          <form onSubmit={submitHandle}>
            <DialogContent
              dividers
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  mt: 5,
                  width: { xs: "100%", lg: "400px" },
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Button>
                  <label
                    style={{ cursor: "pointer" }}
                    htmlFor="inputGroupFile"
                  >
                    <Avatar
                      alt="Remy Sharp"
                      src={url ? url : user.photoURL}
                      sx={{ width: 100, height: 100 }}
                    />
                  </label>

                  <Input
                    accept="image/*"
                    onChange={handleFileChoosed}
                    sx={{ display: "none" }}
                    type="file"
                    id="inputGroupFile"
                  />
                </Button>
              </Box>
              <Box>
                <FormControl
                  sx={{ m: 1, width: { xs: "100%", lg: "400px" } }}
                  variant="outlined"
                >
                  <TextField
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    label="Change Name"
                  />
                </FormControl>
              </Box>
            </DialogContent>
            <DialogActions>
              <Button type="submi" autoFocus onClick={handleCloseDialog}>
                Save changes
              </Button>
            </DialogActions>
          </form>
        </BootstrapDialog>
        {isPeningName && <LinearProgress color="success" />}
        {isPendingImage && <LinearProgress color="success" />}
      </AppBar>
    )
  );
}

export default Header;
