import React, { useEffect, useState } from "react";
import {
  Button,
  FormControl,
  Input,
  InputAdornment,
  InputLabel,
  Box,
  Alert,
} from "@mui/material";
import AccountCircle from "@mui/icons-material/AccountCircle";
import HttpsIcon from "@mui/icons-material/Https";
import logo from "../assets/logo_black_back.png";
import { ReactComponent as Svg } from "../assets/animation.svg";
import { PendingPage } from "../components";

import "./login.css";
import { useNavigate } from "react-router-dom";
import { useLogin } from "../hooks/useLogin";
import { useUserContxt } from "../context/authenticationContext";

function Login() {
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const { login, error } = useLogin();
  const { user, isPending } = useUserContxt();
  const navigate = useNavigate();

  const submit = (e) => {
    e.preventDefault();
    login(email, password);
  };
  useEffect(() => {
    if (user && isPending === false) {
      navigate("/");
    }
  }, [user, isPending, navigate]);

  return !user && isPending === false ? (
    <Box
      sx={{
        width: "100%",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          flex: 1,
          display: { xs: "none", lg: "flex" },
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box>
          <Svg />
        </Box>
      </Box>
      <Box
        sx={{
          flex: 1,
          display: "flex",
          boxShadow: 1,
          borderRadius: "40px 0 0 40px",
          flexDirection: "column",
          p: 2,
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <Box sx={{ width: 150, height: 150, m: 10, mb: 5 }}>
          <img src={logo} alt="logo" />
        </Box>
        <Box
          onSubmit={submit}
          component="form"
          sx={{ width: { xs: "350px", lg: "400px" } }}
        >
          <FormControl variant="standard" sx={{ width: "100%", mb: 5 }}>
            <InputLabel htmlFor="email">EMAIL</InputLabel>
            <Input
              onChange={(e) => setEmail(e.target.value)}
              id="email"
              startAdornment={
                <InputAdornment position="start">
                  <AccountCircle />
                </InputAdornment>
              }
            />
          </FormControl>
          <FormControl variant="standard" sx={{ width: "100%", mb: 5 }}>
            <InputLabel htmlFor="password">PASSWORD</InputLabel>
            <Input
              onChange={(e) => setPassword(e.target.value)}
              id="password"
              type="password"
              startAdornment={
                <InputAdornment position="start">
                  <HttpsIcon />
                </InputAdornment>
              }
            />
          </FormControl>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Button type="submit" variant="contained">
              LOGIN
            </Button>
          </Box>
          {error && <Alert severity="warning">{error}</Alert>}
        </Box>
      </Box>
    </Box>
  ) : (
    <PendingPage />
  );
}

export default Login;
