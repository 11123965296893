import React, { useEffect } from 'react'
import { Box, Button, IconButton } from "@mui/material"
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';

import Tab from "./Tab"
import { useTabsContext } from '../context/tabsContext';
import { useThemeHoc } from '../context/themeContext';


function Application() {
    const { tabs, setTabs, active, setActive } = useTabsContext();

    const { isDark } = useThemeHoc()

    useEffect(() => {
        if (tabs.length === 1) {
            setActive(tabs[0].id)
        }
    }, [tabs, setActive])
    return (
        <Box sx={{ display: "flex", justifyContent: "center", flexDirection: "column" }}>
            <Box sx={{ px: 2, display: "flex", justifyContent: "center", width: "90%" }} >
                <Box sx={{
                    " &::-webkit-scrollbar": { width: "8px" }, " &::-webkit-scrollbar-track": { backgroundColor: "none" }, "&::-webkit-scrollbar-thumb": {
                        backgroundColor: "#888",
                        borderRadius: "20px"
                    }, "&::-webkit-scrollbar-thumb:hover": {
                        backgroundColor: "#555"
                    }, flex: "1", overflowX: "auto", display: "flex", columnGap: 1, maxWidth: '100%', width: "100%", justifyContent: "flex-start"
                }} >
                    {tabs.map((elem, i) => {
                        return (
                            <Box key={elem.id} sx={{ borderRadius: 5, display: "flex", columnGap: 1, border: !isDark ? "1px solid rgba(0,0,0,0.3)" : "1px solid rgba(255,255,255,0.3)" }} >
                                <Button disabled={tabs.length === 1} onClick={() => setActive(elem.id)} sx={{ borderRadius: 5, whiteSpace: 'nowrap' }
                                } variant={elem.id === active ? "contained" : ""} >
                                    {elem.params.length >= 1 ? elem.params.join(" ").substring(0, 15) + "..." : elem.name}
                                </Button>
                                < IconButton disabled={tabs.length === 1} onClick={() => { const indexAc = tabs.findIndex(e => e.id === elem.id); const newState = tabs.filter(e => e.id !== elem.id); tabs.length > 1 && indexAc !== 0 ? setActive(tabs[indexAc - 1].id) : setActive(tabs[1].id); setTabs([...newState]); }} >
                                    <CloseIcon />
                                </IconButton>
                            </Box>

                        )
                    })}

                </Box >
                <Box  >
                    <IconButton onClick={() => setTabs(prev => [...prev, { id: Date.now().toString(), name: `new ${Number(prev[prev.length - 1].name.split(" ")[1]) + 1} Tab`, params: [] }])} sx={{ ml: 1 }} ><AddIcon fontSize="medium" /></IconButton>
                </Box>
            </Box>
            {tabs.map((tab) => {
                return <Tab key={tab.id} tab={tab} active={tab.id === active} />
            })}
        </Box >
    )
}

export default Application