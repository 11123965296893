import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../firebase/firebase";
import { useUserContxt } from "../context/authenticationContext";
import { useState } from "react";

export const useLogin = () => {
  const [error, setError] = useState(null);
  const { dispatch } = useUserContxt();
  const login = (login, password) => {
    signInWithEmailAndPassword(auth, login, password)
      .then((resp) => {
        dispatch({ type: "LOGIN", payload: resp.user });
      })
      .catch((err) => setError(err.message.split("(")[1].split(")")[0]));
  };

  return { login, error };
};
