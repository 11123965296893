import React, { useEffect, useState } from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import {
  Header,
  Application,
  HistoryDiv
} from "../dashboard";
import Loading from "../loading/Loading";
import { useUserContxt } from "../context/authenticationContext";
import { useNavigate } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import { Toolbar, Box } from "@mui/material";
import { PendingPage } from "../components";
import { HistoryContext } from "../context/historyContext";
import { TabsProvider } from "../context/tabsContext";
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
      cacheTime: Infinity,
    },
  },
});



function Dashboard() {

  const { user, isPending } = useUserContxt();
  const [logo, setLogo] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    let timer;


    if (user) {
      timer = setTimeout(() => {
        setLogo(false);
      }, 6000);
    }

    return () => clearTimeout(timer);
  }, [user]);
  useEffect(() => {
    if (!user && isPending === false) {
      navigate("/login");
    }
  }, [user, isPending, navigate]);



  return user && isPending === false ? (
    !logo ? (
      <HistoryContext>
        <TabsProvider>
          <QueryClientProvider client={queryClient}>
            <CssBaseline />
            <Box sx={{ display: 'flex' }} >


              <Header />
              <HistoryDiv />

              <Box component="main" sx={{ flexGrow: 1, p: 1, m: "0 auto ", width: "calc(100% - 240px)" }} maxWidth="xl">
                <Toolbar />
                <Grid container sx={{ mt: 1 }} spacing={2} >

                  <Grid item xs={12} md={12} >
                    <Application />
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </QueryClientProvider>
        </TabsProvider>
      </HistoryContext>
    ) : (
      <Loading />
    )
  ) : (
    <PendingPage />
  );
}

export default Dashboard;
