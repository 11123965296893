import React from "react";
import Loading from "./Loading";

function LoadingContainer() {
  return (
    <div
      style={{
        display: "grid",
        columnGap: "10px",
        rowGap: "20px",
        gridTemplateColumns: "repeat(auto-fit, minmax(250px,1fr))",
      }}
    >
      {Array.apply(null, Array(15)).map((elem, i) => {
        return (
          <div key={i}>
            <Loading />
          </div>
        );
      })}
    </div>
  );
}

export default LoadingContainer;
