import { signOut } from "firebase/auth";
import { auth } from "../firebase/firebase";
import { useUserContxt } from "../context/authenticationContext";
import { useState } from "react";

const useLogout = () => {
  const [error, setError] = useState(null);
  const { dispatch } = useUserContxt();
  const logout = () => {
    signOut(auth)
      .then((resp) => {
        dispatch({ type: "LOGOUT" });
      })
      .catch((err) => {
        setError(err.message);
      });
  };
  return { logout, error };
};

export default useLogout;
