import React, { useEffect, useState } from "react";
import { Box, Toolbar, InputAdornment, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

function FilterBar({ datas, setFiltered }) {
  const [text, setText] = useState("");
  const [data, setData] = useState([]);

  const handleChange = (e) => {
    setText(e.target.value);
    let res = [];
    if (e.target.value === "") {
      setFiltered([...data]);
    } else {
      res = data.filter((elem) => {
        return (
          elem.htmlSnippet
            .toLowerCase()
            .includes(e.target.value.toLowerCase()) ||
          elem.title.toLowerCase().includes(e.target.value.toLowerCase())
        );
      });

      setFiltered(res);
    }
  };
  useEffect(() => {
    setData([...datas]);
  }, [datas]);

  return (
    <Box
      sx={{
        display: "flex",
        mb: 1,
        alignItems: "center",
        justifyContent: "flex-start",
      }}
    >
      <Toolbar
        variant="dense"
        sx={{ display: "flex", justifyContent: "center" }}
      >
        <TextField
          id="input-with-icon-textfield"
          label="Filter Users"
          onChange={handleChange}
          value={text}
          sx={{ minWidth: { md: "300px", lg: "400px" }, mt: 1 }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          variant="standard"
        />
      </Toolbar>
    </Box>
  );
}

export default FilterBar;
