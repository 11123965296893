import React, { memo, useState } from 'react'
import { Box, IconButton, ToggleButton, ToggleButtonGroup, Tooltip } from "@mui/material"
import { useQueries } from "@tanstack/react-query";
import { SearchBar, LoadingContainer, Container } from "."
import ViewListIcon from '@mui/icons-material/ViewList';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import { ContentPaste } from '@mui/icons-material';
import InnerSearch from './InnerSearch';



const Tab = memo(function Tabs({ tab, active }) {
    const [openTooltip, setOpenTooltip] = useState(false);
    const [mode, setMode] = useState("comp");
    const [search, setSearch] = useState([]);
    const [filter, setFilter] = useState("");

    const responses = useQueries({ queries: [...search] })


    const handleClick = (data) => {
        let res = "";
        data[0].map(item => (
            res += item.formattedUrl + "\n"
        ));
        navigator.clipboard.writeText(res);
        setOpenTooltip(true);
        setTimeout(() => {
            setOpenTooltip(false);
        }, 1000);
    };

    const isLoading = responses.some(resp => resp.isLoading);
    const allData = responses.map(elem => elem.data)
    return (
        <Box sx={{ display: active ? "flex" : "none", flexDirection: "column", alignItems: "center", p: 1, mt: 2, width: "100%", height: "100%", borderRadius: "54px 54px 0px 0px" }}  >
            <Box sx={{ flexDirection: { xs: "column", md: "row" }, columnGap: 2, width: "100%", display: "flex", justifyContent: "center" }} >
                <SearchBar tab={tab} setSearch={data => setSearch([...data])} />
            </Box>
            <Box sx={{ mt: 2, width: "100%"}} >
                {allData.length >= 1 && 
                    <Box sx={{ my: 2 ,display: "flex", justifyContent: "space-between" }}>
                        <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
                            <InnerSearch filter={filter} setFilter={(e) => setFilter(e)} />
                            <IconButton onClick={() => handleClick([...allData])} sx={{width: "60px", height: "60px", alignSelf: "flex-end"}}>
                                <Tooltip title="copied!" arrow open={openTooltip} placement="right">
                                    <ContentPaste />
                                </Tooltip>
                            </IconButton>
                        </Box>
                        <Box>
                            <ToggleButtonGroup 
                                exclusive value={mode}
                                onChange={(e, elem) => {
                                    if (elem !== null) {
                                        setMode(elem)
                                }}}
                                sx={{ display: "flex", justifyContent: "center", alignItems: "center" }} >
                                <ToggleButton value="list" >
                                    <ViewListIcon />
                                </ToggleButton>
                                <ToggleButton value="comp" >
                                    <ViewModuleIcon />
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </Box>
                    </Box>
                }
                {isLoading ? <LoadingContainer /> : <Container data={allData} filter={filter} mode={mode} />}
            </Box>
        </Box >
    )
})

export default Tab