import { addDoc, collection, doc, updateDoc  } from "firebase/firestore";
import { useState } from "react";
import { db } from "../firebase/firebase"


export const useUpdateDoc=()=>{
const [pending,setPending]=useState(false)
const [done,setDone]=useState(false);
 const createOne=async (uid,value)=>{
        try{
              await addDoc(collection(db,"histories"),{
                uid:uid,
                history:[value]
            })
        } catch(err){
        }
    }
    const updateDocByID=(id,what,value)=>{
        setDone(false)
        setPending(true)
        const docRef=doc(db,"histories",id);
        try{
              updateDoc(docRef,{[what]:value}).then(()=>{
                setPending(false);
                setDone(true);
              })
               
        }catch(err){
            setPending(false)
        }
    }
    return {createOne,updateDocByID,pending,done};
}